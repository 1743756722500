<template>
  <div>
    <frame>
      <!-- 头部添加优惠券一行 -->
      <div class="head">
        <div class="left">
          <b class="left_tit">自提点管理</b>
        </div>
        <div class="right">
          <el-button class="refresh" @click="refresh()" icon="el-icon-refresh"
            >刷新</el-button
          >
        </div>
      </div>
      <!-- 添加广告文字一行 -->
      <div class="screen">
        <div class="screen_left">
          <i class="el-icon-search"></i>
          新增提货点
        </div>
      </div>

      <!-- 添加表单主题部分 -->
      <div class="center">
        <el-form
          ref="ruleForm"
          :model="ruleForm"
          label-width="100px"
          :rules="ruleValidate"
        >
          <!-- <el-form-item label="适用商户:" v-show="merchantShow">
            <el-select
              v-model="ruleForm.business_id"
              placeholder="请选择商户"
              @change="changeMerchant(ruleForm.business_id)"
              style="width: 300px"
            >
              <el-option
                v-for="item in ruleForm.businessList"
                :key="item.business_id"
                :label="item.business_name"
                :value="item.business_id"
              >
              </el-option>
            </el-select>
          </el-form-item> -->

          <!-- <el-form-item label="适用门店:" v-show="storeShow">
            <el-select
              v-model="ruleForm.storeid"
              placeholder="请先选择门店"
              style="width: 300px"
            >
              <el-option
                v-for="item in ruleForm.storeList"
                :key="item.s_name"
                :label="item.s_name"
                :value="item.storeid"
              >
              </el-option>
            </el-select>
          </el-form-item> -->

          <el-form-item label="自提名称：" prop="name">
            <el-input v-model="ruleForm.name" style="width: 300px"></el-input>
          </el-form-item>

          <el-form-item label="联系方式：" prop="mobile">
            <el-input v-model="ruleForm.mobile" style="width: 300px"></el-input>
          </el-form-item>

          <el-form-item label="选择区域：" prop="province">
            <el-select
              v-model="ruleForm.province.REGION_NAME"
              placeholder="省"
              @change="province($event)"
              style="width: 150px"
            >
              <el-option
                v-for="item in ruleForm.province.provinceList"
                :key="item.REGION_ID"
                :label="item.REGION_NAME"
                :value="item.REGION_ID"
              >
              </el-option>
            </el-select>

            <el-select
              v-model="ruleForm.city.REGION_NAME"
              placeholder="市"
              @change="city($event)"
              style="width: 150px"
            >
              <el-option
                v-for="item in ruleForm.city.cityList"
                :key="item.REGION_ID"
                :label="item.REGION_NAME"
                :value="item.REGION_ID"
              >
              </el-option>
            </el-select>

            <el-select
              v-model="ruleForm.county.REGION_NAME"
              placeholder="区"
              @change="county($event)"
              style="width: 150px"
            >
              <el-option
                v-for="item in ruleForm.county.countyList"
                :key="item.REGION_ID"
                :label="item.REGION_NAME"
                :value="item.REGION_ID"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="详细地址：" prop="address">
            <el-input
              v-model="ruleForm.address"
              style="width: 300px"
            ></el-input>
          </el-form-item>

          <el-form-item>
            <el-button
              @click="submitForm('ruleForm')"
              type="primary"
              style="width: 120px"
              >提交</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </frame>
  </div>
</template>

<script>
import frame from "../../../public/Frame.vue";
export default {
  data() {
    return {
      merchantShow: false, //不同权限下的显示隐藏
      storeShow: false,
      //提交的表单数据
      ruleForm: {
        zid: "", //自提点id
        businessList: [{}], //商户列表
        business_id: "", //选中的商户ID
        storeList: [{}], //门店列表
        storeid: "", //选中的门店ID
        name: "", //自提点名称
        address: "", //详细地址
        mobile: "", //	联系方式
        freightid: "",
        province: {
          provinceList: [{}], //省
          REGION_ID: "", //省份id
          REGION_NAME: "", //省份名称
        },
        city: {
          cityList: [{}], //市
          REGION_ID: "", //市区id
          REGION_NAME: "", //市区名称
        },
        county: {
          countyList: [{}], //区
          REGION_ID: "", //区id
          REGION_NAME: "", //区县名称
        },
      },
      //表单验证数据
      ruleValidate: {
        name: [
          {
            required: true,
            message: "自提点名称", 
            trigger: "blur",
          },
        ],
        mobile: [
          { required: true, message: "手机号不能为空" },
          // { type: "number", message: "手机号必须为数字值" },
        ],
        province: [
          {
            required: true,
            message: "选择区域", 
            trigger: "change",
            validator: this.checkFrequency,
          },
        ],
        address: [
          {
            required: true,
            message: "详细地址", 
            trigger: "blur",
          },
        ],
      },
    };
  },

  // wxe3f1dbde286a46d6
  // d85bebd6521bb7f0bf1f100b2203cc9f
  // 1344296701
  // d86e5d3fd11d1b4d800310ba4a277cea
  // HX10002688
  methods: {
    
    //刷新页面按钮
    refresh() {
      location.reload();
    },

    //选中商户查询门店
    changeMerchant(business_id) {
      console.log(business_id);
      this.$request
        .businessStoreList({
          token: this.$storage.getLocal("token"), //登陆进来的tooken
          admin_type: this.$storage.getLocal("type"), //身份
          business_id: business_id,
        })
        .then((res) => {
          console.log(res);
          this.ruleForm.storeList = res.data;
        });
    },

    //选择省份请求城市列表
    province(e) {
      let obj = {};
      obj = this.ruleForm.province.provinceList.find((item) => {
        //这里的userList就是上面遍历的数据源
        return item.REGION_ID === e; //筛选出匹配数据
      });
      this.ruleForm.province.REGION_NAME = obj.REGION_NAME;
      this.$request
        .regionSel({
          token: this.$storage.getLocal("token"), //登陆进来的tooken
          region_id: obj.REGION_ID,
        })
        .then((res) => {
          this.ruleForm.city.cityList = res.data;
        });
    },

    //选择城市请求区县列表
    city(e) {
      let obj = {};
      obj = this.ruleForm.city.cityList.find((item) => {
        //这里的userList就是上面遍历的数据源
        return item.REGION_ID === e; //筛选出匹配数据
      });
      console.log(obj.REGION_NAME); //获取的 name
      console.log(obj.REGION_ID); //获取的 id
      this.ruleForm.city.REGION_NAME = obj.REGION_NAME;
      this.$request
        .regionSel({
          token: this.$storage.getLocal("token"), //登陆进来的tooken
          region_id: obj.REGION_ID,
        })
        .then((res) => {
          console.log(res);
          this.ruleForm.county.countyList = res.data;
        });
    },

    //选择区县
    county(e) {
      let obj = {};
      obj = this.ruleForm.county.countyList.find((item) => {
        //这里的userList就是上面遍历的数据源
        return item.REGION_ID === e; //筛选出匹配数据
      });
      this.ruleForm.county.REGION_NAME = obj.REGION_NAME;
    },

    //表单提交按钮
    submitForm(formName) {
      var token = this.$storage.getLocal("token")
      /* var province = this.ruleForm.province.REGION_NAME
      var city = this.ruleForm.province.REGION_NAME
      var county = this.ruleForm.province.REGION_NAME */
	  
	
      console.log(formName);
      // console.log(this.$refs[formName].validate);
      
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // alert("submit!");
          console.log(this.ruleForm.zid);
          //自提点添加接口
          let zid=''
		  if(this.ruleForm.zid!==undefined)
		  {
			  let zid=this.ruleForm.zid
		  }
          console.log(typeof(this.ruleForm.city.REGION_NAME));
          this.$request
            .setSelfTake({
              token:token, //登陆进来的tooken
              admin_type: this.$storage.getLocal("type"), //身份
              business_id: this.ruleForm.storeid, //是	int	门店id 超管没有权限添加
              // business_id: 1, //是	int	门店id 超管没有权限添加
              id: zid, //	非	string	传id走编辑
              name: this.ruleForm.name, //是	string	自提点名称
              address: this.ruleForm.address, //	是	int	详细地址
			  
              mobile: this.ruleForm.mobile, //	是	int	联系方式
             /*  province: province, //	是	string	省
              city: city, //	是	string	市
              county: county, //	是	string	区 */
              province: this.ruleForm.province.REGION_NAME, //	是	string	省
              city: this.ruleForm.city.REGION_NAME, //	是	string	市
              county: this.ruleForm.county.REGION_NAME, //	是	string	区
            })
            .then((res) => {
              console.log(res);
              if (res.code == 0) {
                this.$router.push({ path: "liftingPointList" });
              } else {
                // alert("请求失败");
              }
            });
        } else {
          console.log("提交失败");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },

  created() {
    let admin_type = this.$storage.getLocal("type");
    if (admin_type == 1) {
      this.merchantShow = true;
      this.storeShow = true;
    }
    if (admin_type == 2) {
      this.storeShow = true;
      this.$request
        .businessStoreList({
          token: this.$storage.getLocal("token"), //登陆进来的tooken
          admin_type: this.$storage.getLocal("type"), //身份
          // business_id: business_id,
        })
        .then((res) => {
          console.log("帅气的我", res);
          this.ruleForm.storeList = res.data;
        });
    }

    // 请求商户列表
    // this.$request.businessList({}).then((res) => {
    //   console.log(res);
    //   this.ruleForm.businessList = res.data;
    // });

    //请求省份列表
    this.$request
      .regionSel({
        token: this.$storage.getLocal("token"), //登陆进来的tooken
        region_id: "",
      })
      .then((res) => {
        console.log(res);
        this.ruleForm.province.provinceList = res.data;
      });

    let edit = this.$route.query;
    console.log("编辑运费模板的默认值", edit);
    if (edit != "") {
      this.top1 = "";
      // this.merchantShow = false
      console.log(this);
      // this.ruleForm.storeid = 2;
      this.ruleForm.name = edit.name; //是	string	模板名称
      this.ruleForm.zid = edit.id; //是	string	模板名称
      this.ruleForm.mobile = edit.mobile; //是	手机号
      this.ruleForm.address = edit.address; //是	手机号
      this.ruleForm.province.REGION_NAME = edit.province; //是	int	省
      this.ruleForm.city.REGION_NAME = edit.city; //是	int	市
      this.ruleForm.county.REGION_NAME = edit.county; //是	int	县区
    }
    // this.ruleForm = edit
    // this.ruleForm.province.REGION_NAME=edit.REGION_NAME
    // this.$request
    //   .getUpStoreData({
    //     token: this.$storage.getLocal("token"),
    //     storeid: edit.business_id,
    //   })
    //   .then((r) => {
    //     console.log("商户id", r);
    //     if (r.code == 0) {
    //       //   console.log(r);
    //       this.ruleForm.business_id = r.data.pstoreid;
    //       this.$request
    //         .getStoreDataList({
    //           token: this.$storage.getLocal("token"),
    //           pstoreid: r.data.pstoreid,
    //           login_type: this.$storage.getLocal("type"),
    //         })
    //         .then((r) => {
    //           console.log(1233333333333, r);
    //           if (r.code == 0) {
    //             this.ruleForm.storeList = r.data;
    //             this.ruleForm.storeid = parseInt(edit.store_id);

    //             console.log(r);
    //           }
    //         });
    // }
    // });
    // console.log(this.storeid);
  },
  components: {
    frame,
  },
};
</script>

<style  scoped>
.head {
  width: 100%;
  height: 45px;
  line-height: 45px;
  background-color: rgb(243, 243, 243);
}
.left,
.right {
  display: inline;
}
.left {
  float: left;
  margin-left: 210px;
}
.left_tit {
  border-left: 5px solid red;
  font-size: 22px;
  padding: 5px;
}
.refresh {
  float: right;
  margin-right: 10%;
  margin-top: 2px;
}
.screen {
  margin-top: 15px;
  width: 85%;
  margin-left: 200px;
  height: 40px;
  height: 40px;
  background-color: rgb(243, 243, 243);
}

.screen_left {
  float: left;
  margin-left: 50px;
  margin-top: 10px;
}
.center {
  width: 40%;
  margin-left: 32%;
  margin-top: 50px;
  /* height: 500px; */
  /* border: 1px solid red; */
}
/* 上传图片的样式 */
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>